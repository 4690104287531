import request from '@/http/request';

// 首页轮播
export const getBannerListAPI = () => {
  return request.get('/hysk-uccn/banner/getBannerList');
};

// 导航菜单
export const getMenuAPI = () => {
  return request.get('/hysk-uccn/menu/getMenuTree');
};

// 富文本列表
export const getRichListAPI = (menuId) => {
  return request.get(`hysk-uccn/rich/list/${menuId}`);
};

// 文章列表
export const getArticleListAPI = (params) => {
  return request.post(`/hysk-uccn/essay/list`, params);
};

// 文章详情
export const getArticleDetailAPI = (params) => {
  return request.post(`/hysk-uccn/essay/essayBeforeAfterDetail`, params);
};

// 文章置顶列表
export const getEssaySortListAPI = (params) => {
  return request.post('/hysk-uccn/essay/getEssaySortList', params);
};

// 登录
export const loginAPI = (params) => {
  return request.post('/hysk-uccn/userInfo/login', params);
};

// 恒忆学院子菜单文章
export const essayChildListAPI = (params) => {
  return request.post('/hysk-uccn/essayChild/list', params);
};

// 恒忆学院子菜单文章详情
export const getEssayChildDetailAPI = (params) => {
  return request.post(`/hysk-uccn/essayChild/essayBeforeAfterDetail`, params);
};

// 恒忆学院子菜单富文本
export const richChildListAPI = (menuChildId) => {
  return request.get(`/hysk-uccn/richChild/menuList/${menuChildId}`);
};

// 恒忆学院子菜单视频
export const videoChidlListAPI = (params) => {
  return request.post('/hysk-uccn/tbVideo/list', params);
};

// 恒忆学院视频详情
export const videoDetailAPI = (params) => {
  return request.post('/hysk-uccn/tbVideo/videoDetail', params);
};

// 公司信息查询
export const getCompanyInfoAPI = () => {
  return request.post('/hysk-uccn/userInfo/dictCode', {
    dictTypeId: 2207292038335370,
  });
};

// 文章搜索查询
export const searchEssayListAPI = (params) => {
  return request.post('/hysk-uccn/essay/essayList', params);
};
// 获取保险产品
export const productList = (menuId, pageNum, pageSize) => {
  return request.get(`/hysk-uccn/insureRecommend/select/?menuId=${menuId}&pageNum=${pageNum}&pageSize=${pageSize}`);
};