<template>
  <div id="app">
    <Header />
    <KeepAlive exclude="hySchool">
      <router-view />
    </KeepAlive>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
  },
}
</script>

<style lang="less">
</style>
