import axios from 'axios';
import { getToken } from '@/utils';
import { Message } from 'element-ui';

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 1000 * 60 * 2,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  //withCredentials: true,
});

http.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    newConfig.headers['Authorization'] = getToken();
    return newConfig;
  },
  (error) => {
    throw new Error(error);
  }
);

http.interceptors.response.use(
  (response) => {
    const { data, status } = response;

    if (status === 200 && data.code === 1000) {
      return Promise.resolve(data.data);
    }
    Message.error(data.message);
  },
  (error) => Message.error(error) // 返回接口返回的错误信息
);
export default http;
