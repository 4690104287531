import Vue from 'vue';
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import http from './http/request';
import '@/assets/styles/index.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
// import BaiduMap from 'vue-baidu-map';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.$http = http;
// Vue.use(BaiduMap, {
//   ak: '',
// });
console.log("sessionStorage ACTIVEITEM")
console.log(sessionStorage.getItem('ACTIVEITEM'))
store.commit(
  'setActiveItem',
  // JSON.parse(sessionStorage.getItem('ACTIVEITEM') || '{}')
  JSON.parse(sessionStorage.getItem('ACTIVEITEM') && sessionStorage.getItem('ACTIVEITEM') != 'undefined' ? sessionStorage.getItem('ACTIVEITEM') : '{}')
);
store.commit('setNavUrl', sessionStorage.getItem('NAVURL') || '/');
store.dispatch('setMenuAction');
store.dispatch('getCompanyInfo');

// 全局设置rem
const SetRem = (win, doc) => {
  const changeSize = () => {
    const w =
      doc.documentElement.clientWidth > 1440
        ? 1440
        : doc.documentElement.clientWidth;
    doc.documentElement.style.fontSize = w / 19.2 + 'px';
  };
  changeSize();
  win.addEventListener('resize', changeSize, false);
};

SetRem(window, document);

new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount('#app');
