import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import store from '@/store';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/news',
    name: 'News',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/Home/news.vue');
    },
  },
  {
    path: '/about*',
    name: 'About',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/RichPage');
    },
  },
  {
    path: '/service*',
    name: 'Service',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/Brand');
    },
  },
  {
    path: '/values*',
    name: 'Values',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/Culture');
    },
  },
  {
    path: '/group*',
    name: 'Group',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/Group');
    },
  },
  {
    path: '/infoDynamic*',
    name: 'InfoDynamic',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/InfoDynamic');
    },
  },
  {
    path: '/publicWelfare*',
    name: 'PublicWelfare',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/PublicWelfare');
    },
  },
  {
    path: '/charity/detail', // 慈善详情
    name: 'PublicWelfareDetail',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/PublicWelfare/detail');
    },
  },
  {
    path: '/hySchool*',
    name: 'HySchool',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/HySchool');
    },
  },
  {
    path: '/video/detail', // 视频详情页
    name: 'videoDetail',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/HySchool/videoDetail');
    },
  },
  {
    path: '/contactUs*',
    name: 'ContactUs',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/ContactUs');
    },
  },
  // {
  //   path: '/contactUs/address',
  //   name: 'Address',
  //   component: function () {
  //     return import(
  //       /* webpackChunkName: "about" */ '@/views/ContactUs/Address'
  //     );
  //   },
  // },
  // {
  //   path: '/contactUs/recruitment',
  //   name: 'Recruitment',
  //   component: function () {
  //     return import(
  //       /* webpackChunkName: "about" */ '@/views/ContactUs/Recruitment'
  //     );
  //   },
  // },
  {
    path: '/login',
    name: 'Login',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/Login');
    },
  },
  {
    path: '/result',
    name: 'Result',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/components/Empty.vue');
    },
  },
  {
    path: '/result/:keyWord',
    name: 'Result',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/Result');
    },
  },
  {
    path: '/products*',
    name: 'Products',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/products');
    },
  },
  {
    path: '/template1*',
    name: 'TemplatePage',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/TemplatePage');
    },
  },
  {
    path: '/template2*',
    name: 'TemplatePage',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/TemplatePage');
    },
  },
  {
    path: '/template3*',
    name: 'TemplatePage',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/TemplatePage');
    },
  },
  {
    path: '/template4*',
    name: 'TemplatePage',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/TemplatePage');
    },
  },
  {
    path: '/template5*',
    name: 'TemplatePage',
    component: function () {
      return import(/* webpackChunkName: "about" */ '@/views/TemplatePage');
    },
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

router.beforeEach(function (to, from, next) {
  sessionStorage.setItem('curPath', to.path)
  const menus = JSON.parse(sessionStorage.getItem('menus'))
  console.log('router.beforeEach', menus)
  if (menus && menus.length > 0) {
    const obj = menus.find((item) => item.path == to.fullPath)
    if (obj) {
      sessionStorage.setItem('ACTIVEITEM', JSON.stringify(obj))
      store.commit('setActiveItem', obj)
    }
  }
  if (to.fullPath == '/hySchool') {
    const { token = '' } = JSON.parse(sessionStorage.getItem('USER_INFO') || '{}')
    if (token) {
      next()
    } else {
      next('/login')
    }

  }
  next()
})

export default router;
