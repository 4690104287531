var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"headerContainer",class:{
      'headerContainer--home': _vm.homeState,
      'headerContainer--group': _vm.groupState,
    },attrs:{"id":"header"}},[_c('div',{staticClass:"inner"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logo,"alt":""}}),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showSearch),expression:"!showSearch"}],staticClass:"list"},_vm._l((_vm.menus),function(item){return _c('li',{key:item.id,staticClass:"listItem",class:{
            active: item.path != '/group' && item.path === _vm.navUrl,
            'listItem--home': _vm.homeState,
            'listItem--group': _vm.groupState,
          },attrs:{"id":"listItem"},on:{"click":function($event){return _vm.jump(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])]),(!_vm.loading)?_c('div',{staticClass:"loading"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }